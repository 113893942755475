body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.messages-box {
  background-color: lightgray;
  border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 4px;
  min-height: 300px;
  max-height: 350px;
  overflow-y: scroll;
}

.message-box {
  background-color: rgb(86, 86, 86);
  max-width: 540px;
  min-width: 540px;
  margin: 10px;
  color: white;
  padding: 15px;
}

.sent {
  border-left: 6px solid #0e7bb7;
}

.received {
  border-left: 6px solid #DC3545;
}

.fetch-active {
  background-color: lightgray;
}

.no-messages {
  margin: auto;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.help-item:hover {
  background-color: #bebfc0;
}
